<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable max-width="700">
      <v-card>
        <v-card-title class="headline"> {{ $t('customer.viewtitle') }} ({{ data.id }})</v-card-title>
        <v-card-text>
          <v-tabs fixed-tabs>
            <v-tab @click="showTab = 'info'"> {{ $t('customer.info') }} </v-tab>
            <v-tab @click="showTab = 'stat'"> {{ $t('customer.stat') }} </v-tab>
            <v-tabs-slider color="blue"></v-tabs-slider>
          </v-tabs>
          <h3>{{ data.name }}</h3>
          <br />
          <v-card v-if="showTab == 'info'">
            <v-row>
              <v-col>
                {{ $t('customer.group') }} :{{ data.group_name }}<br />
                {{ $t('customer.phone') }} :{{ data.phone }}
                <v-btn @click="mzCall(data.phone)" text x-small><v-icon>fa-phone</v-icon></v-btn>
                E-mail:{{ data.email }}<br />
                {{ $t('customer.addr') }} :{{ data.addr }}<br />
                {{ $t('customer.recommendation') }} :{{ data.recommendations }}<br />
                {{ $t('customer.community') }} :{{ data.communication_name }}<br />
                <span v-if="data.flg_contract"
                  ><b> {{ $t('customer.flgdogovor') }} </b><br
                /></span>
              </v-col>
              <v-col
                ><span v-for="fld in data.customerFields" v-bind:key="fld.id">
                  <b v-if="fld.tpe == 4 && fld.value == 1">{{ fld.name }}<br /></b>
                  <span v-if="fld.tpe != 4">{{ fld.name }} :{{ fld.value }}<br /></span>
                </span>
              </v-col>
            </v-row>

            <br />

            {{ $t('customer.comment') }} :{{ data.comment }}<br />
          </v-card>
          <v-card v-if="showTab == 'stat'">
            {{ $t('customer.order') }} :<a href="#" @click="history">{{ data.stat.job_count }}</a
            ><br />
            {{ $t('customer.calls') }} :<a href="#" @click="historyCall">{{ data.stat.call_count }}</a
            ><br />
            {{ $t('customer.lastjob') }}:{{ data.stat.job_last }}<br />
            {{ $t('customer.jobsmm') }}:{{ data.stat.job_pay }}<br />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" fab @click="del" x-small><v-icon>fa-trash</v-icon></v-btn>
          <v-btn color="success" fab @click="modalJob = { id: data.id, name: data.name, phone: data.phone }" x-small
            ><v-icon>fa-pencil</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
          <v-btn color="primary" dark @click.native="edit">
            {{ $t('all.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modalEdit v-if="modal" v-bind:title="modalTitle" v-bind:view="modalView" v-on:close="modalClose"></modalEdit>
    <modal_history v-if="modalhistory" v-bind:view="modalView" v-on:close="modalClose"></modal_history>
    <modal_mzhistory v-if="modalhistoryMz" v-bind:view="modalhistoryMz" v-on:close="modalClose"></modal_mzhistory>
    <modalJob v-if="modalJob" v-bind:inCustomer="modalJob" v-bind:view="[]" v-on:close="modalClose"></modalJob>
  </div>
</template>

<script>
import modalEdit from './customer_edit.vue'
import modal_history from './customer_history.vue'
import modal_mzhistory from './customer_mzhistory.vue'
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const messageBox = create(confirm)
// import modalJob_ from './../job/job_edit.vue'
export default {
  props: ['view'],
  name: 'customer_view',
  data() {
    return {
      dialog: true,
      showTab: 'info',
      data: [],
      modal: '',
      modalhistory: '',
      modalTitle: '',
      modalView: '',
      id: '',
      modalhistoryMz: '',
      modalJob: '',
    }
  },
  components: {
    modal_history,
    modal_mzhistory,
    modalEdit,
    modalJob: () => import('./../job/job_edit.vue'),
  },
  mounted: function() {
    this.id = this.view
    console.log('this.view', this.view)
    this.dataLoad()
    this.dialog = true
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modalhistory = ''
      this.modalhistoryMz = ''
      this.modalJob = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http.post(this.$store.state.apiUrl + 'customer/index/' + t.id).then(
        response => {
          t.$store.commit('loadingStartShow', false)
          if (response.body.err > 0) {
            console.log(response.body)
          } else {
            t.data = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    edit: function() {
      this.modal = true
      this.modalView = this.data.id
    },
    del: function() {
      var t = this
      //                console.log(t.data)
      messageBox({
        text: t.$t('customer.confdel', [t.data.name]),
        type: 'delete',
        title: t.$t('customer.deltitle'),
      })
        .transition()
        .then(response => {
          if (response) {
            t.delSave()
          }
        })
    },
    delSave: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'customer/delete/', {
          id: this.data.id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              this.$emit('close', 'reload')
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    history: function() {
      this.modalhistory = true
      this.modalView = this.data.id
    },
    historyCall: function() {
      this.modalhistoryMz = this.data.id
    },
    mzCall: function(phone) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'mz/call/', {
          phone: phone,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
          },
          err => {
            console.log(err)
          },
        )
    },
  },
}
</script>

<style></style>
