<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('customer.title') }}</h3>
      </v-col>
      <v-col class="text-right">
        <v-btn outlined small color="primary" @click="link('customer_group')">{{ $t('customer.grouptitle') }}</v-btn>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <v-row>
      <v-col class="d-inline-flex">
        <v-text-field :label="$t('all.find')" v-model="search" append-icon="fa-search"></v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn small color="success" @click="addUser"> <v-icon dark>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      dense
      v-bind:headers="headers"
      locale="ru-ru"
      :items="tableData"
      class="elevation-1 row-pointer"
      v-bind:search="search"
      @click:row="viewUser"
    >
    </v-data-table>

    <modal_edit v-if="modal" v-bind:title="modalTitle" v-bind:view="modalView" v-on:close="modalClose"></modal_edit>
    <modal_view v-if="modal_view" v-bind:view="modalView" v-on:close="modalClose"></modal_view>
  </div>
</template>

<script>
import modal_view from './customer_view.vue'
import modal_edit from './customer_edit.vue'
export default {
  data() {
    return {
      headers: [
        { text: this.$t('customer.name'), align: 'left', value: 'name' },
        { text: this.$t('customer.group'), align: 'left', value: 'group_name' },
        { text: this.$t('customer.phone'), align: 'left', value: 'phone' },
        { text: 'E-mail', align: 'left', value: 'email' },
        { text: this.$t('customer.comment'), align: 'left', value: 'comment' },
      ],
      tableData: [],
      modal: '',
      modal_view: '',
      modalTitle: '',
      modalView: '',
      search: '',
      showTab: 'customer',
    }
  },
  components: {
    modal_edit,
    modal_view,
  },
  computed: {
    resource: function() {
      return this.$store.state.auth.resource
    },
  },
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modal_view = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http.post(this.$store.state.apiUrl + 'customer').then(
        response => {
          t.$store.commit('loadingStartShow', false)
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    addUser: function() {
      this.modal = true
      this.modalView = null
    },
    editUser: function(view) {
      this.modal = true
      this.modalView = view.id
    },
    viewUser: function(view) {
      this.modal_view = true
      this.modalView = view.id
    },
    link: function(view) {
      this.$router.push(view)
    },
  },
}
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
