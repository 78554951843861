<template>
  <div>
    <v-dialog v-model="dialog" fullscreen persistent scrollable>
      <v-card>
        <v-card-title class="headline">История звонков</v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Тип</th>
                <th>Дата</th>
                <th>Статус</th>
                <th>Телефон</th>
                <th>Клиент</th>
                <th>Длительность</th>
                <th>Ссылка</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sr, index) in data" :key="index">
                <td>{{ sr.type_name }}</td>
                <td>{{ sr.start }}</td>
                <td>{{ sr.status_name }}</td>
                <td>{{ sr.phone }}</td>
                <td>{{ sr.customer_fio }}</td>
                <td>{{ sr.duration }}</td>
                <td>
                  <a
                    v-if="sr.record_link"
                    :href="sr.record_link"
                    target="_blank"
                    >Ссылка</a
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="$emit('close')">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['view'],
  data() {
    return {
      dialog: true,
      data: [],
      id: '',
    }
  },
  components: {},
  mounted: function () {
    this.id = this.view
    this.dataLoad()
    this.dialog = true
  },
  computed: {},
  methods: {
    dataLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'mz/history/' + t.id).then(
        (response) => {
          if (response.body.err > 0) {
            console.log(response.body)
          } else {
            t.data = response.body
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
  },
}
</script>

<style></style>
