<template>
  <div>
    <v-dialog v-model="dialog" fullscreen persistent scrollable>
      <v-card>
        <v-card-title class="headline">
          {{ $t('customer.historyjobtitle') }}</v-card-title
        >
        <v-card-text>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">{{ $t('all.date') }}</th>
                <th class="text-left">{{ $t('all.job') }}</th>
                <th class="text-left">{{ $t('job.item') }}</th>
                <th class="text-left">{{ $t('all.cost') }}</th>
                <th class="text-left">{{ $t('job.paid') }}</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sr, index) in data" :key="index">
                <td>{{ sr.dte_create }}</td>
                <td>
                  <a href="#" @click="openJob(sr)">{{ sr.name }}</a>
                </td>
                <td>{{ sr.items }}</td>
                <td>{{ sr.cost }}</td>
                <td>{{ sr.pay }}</td>
                <td>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">
                        {{ $t('all.print') }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="item in docprints"
                        :key="item.id"
                        @click="docPrint(sr.id, item.id)"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <job_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
    ></job_edit>
  </div>
</template>

<script>
//    import chmodal_edit from './../job/job_edit.vue'

export default {
  props: ['view'],
  name: 'customer_history',
  // beforeCreate: function () {
  //   this.$options.components.job_edit = require('./../job/job_edit.vue')
  // },

  data() {
    return {
      dialog: true,
      data: [],
      id: '',
      modal: '',
      modal_delete: '',
      modal_log: '',
      modalTitle: '',
      modalView: [],
    }
  },
  components: {
    job_edit: () => import('./../job/job_edit.vue'),
    //            chmodal_edit: () => import('./../job/job_edit.vue')
    //                    chmodal_edit,
  },
  mounted: function () {
    this.id = this.view
    this.$store.dispatch('loadDocprint')
    this.dataLoad()
    this.dialog = true
  },
  computed: {
    docprints: function () {
      return this.$store.state.spr.docprint
    },
  },
  methods: {
    dataLoad: function () {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'customer/history/' + t.id)
        .then(
          (response) => {
            if (response.body.err > 0) {
              console.log(response.body)
            } else {
              t.data = response.body
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    docPrint: function (job, id) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/docprint/', {
          job: job,
          id: id,
        })
        .then(
          (response) => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              var new_win = window.open()
              var content =
                '<html><head>' +
                '<title></title></head><body>' +
                t.escapeHtml(response.body.html) +
                '</body></html>'
              new_win.document.write(content)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    escapeHtml: function (str) {
      if (typeof str == 'string') {
        str = str.replace(/&gt;/gi, '>')
        str = str.replace(/&lt;/gi, '<')
        str = str.replace(/&#039;/g, "'")
        str = str.replace(/&quot;/gi, '"')
        str = str.replace(/&amp;/gi, '&') /* must do &amp; last */
      }
      return str
    },
    openJob: function (view) {
      console.log(view)
      this.modal = true
      this.modalView = view
      return false
    },
    modalClose: function () {
      this.modal = ''
    },
  },
}
</script>

<style></style>
